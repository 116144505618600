import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

function getDomain() {
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.store')) {
    return 'wangoon.store'
  } else {
    return domain
  }
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  console.log(document.domain, window.location.host)
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.store')) {
    return Cookies.set(TokenKey, token, { domain: 'wangoon.store' })
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken() {
  Cookies.remove(TokenKey)
  return Cookies.remove(TokenKey, { domain: getDomain() })
}

export function getLoginAddress() {
  return process.env.VUE_APP_RIGHTS_API
}
export function getExcelExport() {
  return process.env.VUE_APP_IMS_PORT_API
}
