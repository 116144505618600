<template>
  <footer>
    <div class="footer-select">
      <div class="qrcode" @click="showDialog">
        <div class="line" />
        <img class="img" :src="require('../../../assets/img/pda_download.png')" :alt="$t('page.pdaQrcode')">
      </div>
      <router-link class="navigation" to="/">{{ $t('footer.help') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.privacy') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.clause') }}</router-link>
    </div>
    <div class="company-name">wangoon©弯弓信息科技有限公司</div>
    <el-dialog :title="$t('page.pdaQrcode')" :visible.sync="showImgDialog">
      <div class="pda">
        <img :src="require('../../../assets/img/pda_download.png')" alt="">
      </div>
    </el-dialog>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      showImgDialog: false
    }
  },
  methods: {
    showDialog() {
      this.showImgDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  margin-top: 40px;
  color:#858687;
  font-size: 14px;

}
.footer-select {
  display: flex;
  justify-content: center;
  align-items: center;
  .navigation {
    padding: 10px;
  }
  .qrcode {
    position: relative;
    width: 30px;
    height: 30px;
    overflow: hidden;
    .img {
      width: 30px;
      height: 30px;
    }
    .line {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 255, 51, 0) 20%, #00ff33 211%);
      border-bottom: 1px solid #00ff33;
      transform: translateY(-100%);
      animation: radar-beam 2s infinite;
      animation-timing-function: cubic-bezier(0.53, 0, 0.20, 0.99);
      animation-delay: 1s;
    }
  }
}
.company-name {
  text-align: center;
}
.pda {
  text-align: center;
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
