import Cookies from 'js-cookie'
import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  menu: [],
  tenantId: Cookies.get('tenantId'),
  userId: '',
  authorization: 'Bearer ' + getToken(),
  warehouse: Cookies.get('warehouse'),
  warehouseList: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    state.authorization = 'Bearer ' + token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MENU: (state, menu) => {
    state.menu = menu
  },
  SET_TENANT_ID: (state, tenantId) => {
    state.tenantId = tenantId
    Cookies.set('tenantId', tenantId)
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_WAREHOUSE: (state, warehouse) => {
    state.warehouse = warehouse
    Cookies.set('warehouse', warehouse)
  },
  SET_WAREHOUSE_LIST: (state, warehouseList) => {
    state.warehouseList = warehouseList
  }
}
const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, deviceId, validCode } = userInfo

    const bodyFormData = new FormData()

    bodyFormData.set('username', username)
    bodyFormData.set('password', password)
    bodyFormData.set('deviceId', deviceId)
    bodyFormData.set('validCode', validCode)

    return new Promise((resolve, reject) => {
      login(bodyFormData).then(response => {
        const { datas } = response
        commit('SET_TOKEN', datas.access_token)
        setToken(datas.access_token)
        resolve()
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { datas } = response
        if (!datas) {
          reject('Verification failed, please Login again.')
        }
        const { avatar, username, id, tenants } = datas
        commit('SET_NAME', username)
        commit('SET_AVATAR', avatar)
        commit('SET_USER_ID', id)
        commit('SET_WAREHOUSE_LIST',
          tenants.map(e => Object.assign(e, {
            value: e.warehouseId + '-' + e.tenantId,
            label: e.warehouseName + '-' + e.tenantName
          })
          )
        )
        if (!state.warehouse || !state.tenantId || !state.warehouseList.some(e => e.value === (state.warehouse + '-' + state.tenantId))) {
          commit('SET_WAREHOUSE', tenants[0].warehouseId)
          commit('SET_TENANT_ID', tenants[0].tenantId)
        }
        resolve(datas)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      logout().then(response => {
        commit('SET_ROLES', [])
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        removeToken()
        resetRouter()
        commit('permission/SET_ROUTES', [], { root: true })
        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })
        resolve()
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },
  setWarehouse({ commit }, warehouse) {
    commit('SET_WAREHOUSE', warehouse)
  },
  setTenantId({ commit }, tenantId) {
    commit('SET_TENANT_ID', tenantId)
  },
  setWarehouseList({ commit }, warehouseList) {
    commit('SET_WAREHOUSE_LIST', warehouseList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
