const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  // 全局仓库
  warehouse: state => state.user.warehouse,
  warehouseList: state => state.user.warehouseList,

  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  tenantId: state => state.user.tenantId,
  userId: state => state.user.userId,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  menu: state => state.user.menu,
  authorization: state => state.user.authorization,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  // dialog
  visibleFirst: state => state.dialog.visibleFirst,
  visibleSecond: state => state.dialog.visibleSecond,
  visibleThird: state => state.dialog.visibleThird,
  visibleFourth: state => state.dialog.visibleFourth,
  visibleFifth: state => state.dialog.visibleFifth,
  visibleSixth: state => state.dialog.visibleSixth,
  // permissions
  permissions: state => state.permission.permissions,
  // page
  shipmentTableDatasSelect: state => state.pages.shipmentTableDatasSelect,

  // 卡片头部
  tabsOption: state => state.tabsHeadCard.tabsOption,
  currentKey: state => state.tabsHeadCard.currentIndex
}
export default getters
