import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'

const routerMap = {
  Layout,
  NullRouterView,
  Dashboard: () =>
    import('@/views/dashboard/index'),
  InventoryInquiry: () =>
    import('@/views/inventory-manage/inventory-inquiry/index'),
  SkuDimension: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/index'),
  SkuAvailableInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
  SkuInventoryDetails: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
  SkuInventoryPlan: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-planned-inventory/index'),
  SkuPurchaseInventoryTransit: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
  SkuPurchaseInventoryDelivery: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-inventory-delivery'),
  SkuCommitmentInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
  SkuLockInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
  SkuOutgoingInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
  // 仓库维度
  Warehouse: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/index'),
  WareTransitInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-transit-inventory/index'),
  WareDeliveryTransit: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-delivery-transit'),
  WareInventoryDetail: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-inventory-detail/index'),
  WareInventoryPlan: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-planned-inventory'),
  WareAvailableInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-available-inventory/index'),
  WareCommitmentInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-commitment-inventory/index'),
  WareLockInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-lock-inventory/index'),
  WareOutgoingInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/warehouse/ware-outgoing-inventory/index'),
  // 货主维度
  Consignor: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/index'),
  ConsTransitInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-transit-inventory/index'),
  ConsInventoryDetail: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-inventory-details/index'),
  ConsAvailableInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-available-inventory/index'),
  ConsPlannedInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-planned-inventory/index'),
  ConsLockInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-lock-inventory/index'),
  ConsOutgoingInventory: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-outgoing-inventory/index'),
  ConsDeliveryTransit: () =>
    import('@/views/inventory-manage/inventory-inquiry/consignor/cons-delivery-transit/index'),
  // 处置申请
  InventoryHandling: () =>
    import('@/views/inventory-manage/inventory-handling/index'),
  DisposalApply: () =>
    import('@/views/inventory-manage/inventory-handling/disposal-apply/index'),
  ApplyAdded: () =>
    import('@/views/inventory-manage/inventory-handling/disposal-apply/added'),
  ApplyDetails: () =>
    import('@/views/inventory-manage/inventory-handling/disposal-apply/details'),
  InventoryDisposal: () =>
    import('@/views/inventory-manage/inventory-handling/inventory-disposal/index'),
  DisposalAdded: () =>
    import('@/views/inventory-manage/inventory-handling/inventory-disposal/added'),
  DisposalDetails: () =>
    import('@/views/inventory-manage/inventory-handling/inventory-disposal/details'),

  // 库内管理/入库管理
  StockIn: () =>
    import('@/views/ware-manage/stock-in/index'),
  InboundManage: () =>
    import('@/views/ware-manage/stock-in/inbound-manage/index'),
  InboundDetails: () =>
    import('@/views/ware-manage/stock-in/inbound-manage/details'),
  ReceiptManage: () =>
    import('@/views/ware-manage/stock-in/receipt-manage/index'),
  ReceiptDetails: () =>
    import('@/views/ware-manage/stock-in/receipt-manage/details'),
  ShelfManage: () =>
    import('@/views/ware-manage/stock-in/shelf-manage/index'),
  ShelfDetails: () =>
    import('@/views/ware-manage/stock-in/shelf-manage/details'),
  // /出库管理
  StockOut: () =>
    import('@/views/ware-manage/stock-out'),
  exManage: () =>
    import('@/views/ware-manage/stock-out/ex-manage'),
  AmazonShipment: () =>
    import('@/views/ware-manage/stock-out/ex-manage/amazon-shipment'),
  CreateShipment: () =>
    import('@/views/ware-manage/stock-out/ex-manage/amazon-shipment/create-shipment'),
  GeneratShipment: () =>
    import('@/views/ware-manage/stock-out/ex-manage/amazon-shipment/generat-shipment'),
  exManageDetails: () =>
    import('@/views/ware-manage/stock-out/ex-manage/details'),
  shipmentManage: () =>
    import('@/views/ware-manage/stock-out/shipment-manage'),
  shipmentManageDetails: () =>
    import('@/views/ware-manage/stock-out/shipment-manage/details'),
  waybillManage: () =>
    import('@/views/ware-manage/stock-out/waybill-manage'),
  waybillManageDetails: () =>
    import('@/views/ware-manage/stock-out/waybill-manage/details'),
  // /出库管理
  InventoryResults: () =>
    import('@/views/period-manage/inventory-results/index'),
  ResultsDetail: () =>
    import('@/views/period-manage/inventory-results/details'),
  InventoryBalance: () =>
    import('@/views/period-manage/inventory-balance/index'),
  BalanceDetails: () =>
    import('@/views/period-manage/inventory-balance/details'),
  // 差异处理

  DiffHandling: () =>
    import('@/views/inventory-manage/diff-handling/index'),
  HandlingDetail: () =>
    import('@/views/inventory-manage/diff-handling/details'),
  // 基础信息
  Storage: () => import('@/views/basic-data/storage/index'),
  StorageAdded: () => import('@/views/basic-data/storage/added/index'),
  ManualDefict: () => import('@/views/inventory-manage/manual-disk/index'),
  TransitManage: () => import('@/views/inventory-manage/transit-manage/index'),
  ExportFileIms: () => import('@/views/export-file/ExportFileIms/index'),
  BrushData: () => import('@/views/ims-widget/brush-data/index')
}

export default routerMap

