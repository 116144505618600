export default {
  route: {
    dashboard: 'Homepage',
    InventoryInquiry: 'Inventory inquiry ',
    SkuDimension: 'Sku dimension',
    SkuInventoryDetails: 'Sku inventory details',
    SkuInventoryPlan: 'SKU Planned inventory',
    SkuAvailableInventory: 'Sku available inventory',
    SkuPurchaseInventoryTransit: 'Sku inventory transit',
    SkuPurchaseInventoryDelivery: 'Sku inventory delivery',
    BasicData: 'Basic Data',
    Storage: 'Storage',
    Add: 'Add/Edit',
    ManualDefict: 'Inventory  Defict',
    TransitManage: 'Inventory management',
    ExportFileIms: 'ExportFile',
    imsWidget: 'IMS Widget'

  },
  navbar: {
    dashboard: 'Homepage',
    github: 'Github',
    logOut: 'Log out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global size'
  },
  login: {
    title: 'Login Form for IMS',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    verificationCode: 'Verification Code'
  },
  footer: {
    help: 'help',
    privacy: 'privacy',
    clause: 'clause'
  },
  page: {
    // summer
    required: 'Required',
    alreadySel: 'Already selected',
    rowS: 'rows',
    empty: 'Empty',
    fileText: 'Drag files here, or',
    fileTextUpload: 'Click upload',
    OnlyXlsAndXlsxFilesCanBeUploaded: 'Only.xls and.xlsx files can be uploaded',
    UploadFileSizeShouldNotExceed: 'Upload file size should not exceed 1MB!',
    UploadAndImportFiles: 'Upload and import files',
    TheImportDetails: 'The import details',
    setAccountDisplay: 'Set account display',
    inventoryUpload: 'Inventory upload',
    ebayUploadTip: 'Are you sure you want to upload your inventory to EBAY?',
    selectOneData: 'Select at least one valid piece of data',
    /** 公用 */
    LoginFailed: 'Login failed',
    Relogin: 'Re-login',
    HaveBeenLoggedOut: 'You have been logged out, you can cancel to stay on this page, or log in again',
    PasswordManagement: 'Password management',
    Password: 'Password',
    Newpassword: 'New password',
    ConfirmPassword: 'Confirm password',
    ResetPassword: 'Reset password',
    Character: 'Character',
    Staff: 'Staff',
    phoneNumber: 'Phone Number',
    InformationManagement: 'Information management',
    username: 'Username',
    Email: 'Email',
    Avatar: 'Avatar',
    requestTimeOut: 'The request has timed out. Please try again. ',
    homepage: 'homepage',
    Details: 'Details',
    search: 'Search',
    reset: 'Reset',
    expand: 'Expand',
    hide: 'Hide',
    create: 'Create',
    export: 'Export',
    stockHistoryExport: 'Stock history export',
    startTimeEndTime: 'StartTime-EndTime',
    No: 'No',
    inputPlaceholder: 'please enter',
    selectPlaceholder: 'please select',
    enabled: 'Enabled',
    disable: 'Disable',
    operate: 'Operate',
    view: 'View',
    modify: 'Modify',
    invalid: 'Invalid',
    createDate: 'Create Date',
    createTime: 'Create Time',
    receiveTime: 'Receive Time',
    finishTime: 'Finish Time',
    handleTime: 'Handle Time',
    createByName: 'Creator',
    warehouse: 'Warehouse',
    Linkman: 'Linkman',
    sure: 'Sure',
    delete: 'Delete',
    add: 'Add',
    import: 'Import',
    templateDownload: 'Template Download',
    close: 'Close',
    Specifications: 'Specifications',
    total: 'Total',
    status: 'Status',
    Prompt: 'Prompt',
    SkuAvailableInventory: 'SkuAvailableInventory',
    // shipment明细
    addOutboundDetails: 'Add Outbound Details',
    addLables: 'Add Labels',
    notApprove: 'Not approve',
    approved: 'Approved',
    approveShipment: 'Approve Shipment',
    downloadPortMark: 'Download PortMark',
    pickTotalNum: 'Pick Total Num',
    totalBoxNumLabelled: 'Total Box Num Labelled',
    shipmentLabelDetail: 'Shipment label Detail',
    combinationLabelReplenishPrint: 'Combination label Replenish Print',
    exportLabels: 'Export Labels',
    LabelCode: 'Label Code',
    ShippingStatus: 'Shipping Status',
    ShipmentDetails: 'Shipment Details',
    pickDiff: 'Pick Diff',
    labelDiff: 'Label Diff',
    shippingDiff: 'Shipping Diff',
    trajectory: 'trajectory detail',
    ReplenishPickingTask: 'Replenish Picking Task',
    shipmentBoxNum: 'Shipment Box Num',
    shipmentNum: 'Shipment Num',
    pickedNum: 'Picked Num',
    shippingNum: 'Shipping Num',
    shipmentVolume: 'Shipment Volume',
    amazonNumber: 'Amazon number',
    modifyShipmentDetail: 'Modify Shipment Detail Number',
    reduceQuantity: 'Reduce quantity',
    reducedQuantityGreater0: 'The reduced quantity needs to be greater than 0, less than or equal',
    modifyAfterTips: '(After modification, please manually update the Amazon backend quantity)',

    // 添加出库明细到shipment
    addOutboundToShipment: 'Add Outbound To Shipment',
    outboundDetailInfo: 'Outbound Detail Info',
    oneKeyDistribution: 'OneKey Distribution',
    B0oneKeyDistribution: 'B0 OneKey Distribution',
    X0oneKeyDistribution: 'X0 OneKey Distribution',
    GeneratedShipmentNum: 'Generated ShipmentNum',
    NotGeneratedShipmentNum: 'Not Generated ShipmentNum',
    GenerateShipmentNum: 'Generate ShipmentNum'

  },
  title: {
    starttime: 'Starting time',
    endtime: 'End Time',
    Permission: 'Permission',
    back: 'Back',
    Save: 'Save',
    Edit: 'Edit',
    Replace: 'Replace',
    BasicInformation: 'Basic information',
    submit: 'Submit',
    cancel: 'Cancel',
    confirm: 'Confirm',
    ExportDetail: 'Export detail',
    // SKU材料组件管理
    Components: 'Components',
    OtherInformation: 'SKU other information',
    // 平台SKU信息
    Color: 'Color',
    Size: 'Size',
    Category: 'Category',
    Yes: 'Yes',
    No: 'No',
    All: 'All',
    to: 'to',
    platform: 'platform',
    order: 'order',
    copy: 'copy',
    None: 'None',
    create: 'create',
    InventoryDetails: 'Inventory details',
    /** 公用 */
    requestTimeOut: 'The request has timed out. Please try again. ',
    homepage: 'homepage',
    Details: 'Details',
    search: 'Search',
    reset: 'Reset',
    expand: 'Expand',
    hide: 'Hide',
    export: 'Export',
    stockHistoryExport: 'Stock history export',
    startTimeEndTime: 'StartTime-EndTime',
    inputPlaceholder: 'please enter',
    inputEnter: 'enter key separated',
    imputEnter: 'Please press enter to continue',
    inputCompletePlaceholder: 'Please enter complete rules',
    selectPlaceholder: 'please select',
    enabled: 'Enabled',
    disable: 'Disable',
    operate: 'Operate',
    view: 'View',
    modify: 'Modify',
    invalid: 'Invalid',
    createDate: 'Create Date',
    createTime: 'Create Time',
    receiveTime: 'Receive Time',
    finishTime: 'Finish Time',
    handleTime: 'Handle Time',
    createByName: 'Creator',
    lastModifyTime: 'last Modify Time',
    warehouse: 'Warehouse',
    serviceprovider: 'Service provider',
    Linkman: 'Linkman',
    sure: 'Sure',
    delete: 'Delete',
    add: 'Add',
    import: 'Import',
    templateDownload: 'Template Download',
    tray: 'Tray',
    storageArea: 'Storage Area',
    expectedNum: 'Expected Num',
    expectedBoxNum: 'Expected Box Num',
    close: 'Close',
    Specifications: 'Specifications',
    total: 'Total',
    refreshtotal: 'Refresh total',
    alreadySel: 'Already selected',
    rowS: 'rows',
    batchRemove: 'Batch Remove',
    batchCreate: 'Batch Create',
    isSplit: 'Whether (B0/X0/other) split',
    typeBy: 'Type',
    stopReport: 'Stop Report',
    reportReason: 'Report Reason',
    inputStopReason: 'Please input stop reason',
    AddTXT: 'ADD',
    plzChooseDate: 'Please choose date',
    plzChooseDateTime: 'Please choose date and time',
    pdaQrcode: 'Scan QRcode to updata pda',
    purchaseInbound: 'Purchase inbound',
    transferingInbound: 'Transfering inbound',
    returnsInbound: 'Returns inbound',
    manufacturingInbound: 'Manufacturing consigned inbound',
    OverseasInbound: 'Overseas inbound',
    expectedReleaseDate: 'Expected Release Date',
    orderNo: 'order No',
    // Shipment管理
    printtypes: 'Please select the print type',
    printType: 'Print type',
    LabelPaper: 'Label paper',
    page1: '1 page 1',
    page2: '1 page 2',
    page4: '1 page 4',
    page6: '1 page 6',
    // 文件导出
    ExportTaskName: 'Export Task Name',
    ExportCompleTime: 'Export Completion Time',
    AbnormalInformation: 'Abnormal Information'
  }
}
