import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'
/**
 * hidden: true                   当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']     设置该路由进入的权限，支持多个权限叠加
    title: 'title'                设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'              设置该路由的图标
    noCache: true                 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    affix: true                   如果设置为true，则标记将附加在标记视图中
    breadcrumb: false             如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/example/list'   如果设置路径，侧栏将突出显示您设置的路径
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [

  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path*',
      component: () =>
        import('@/views/redirect/index')
    }]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile',
    hidden: true,
    children: [{
      path: '/',
      component: () =>
        import('@/views/profile'),
      name: 'Profile',
      meta: {
        title: 'Profile'
      }

    }]
  },
  {
    path: '/login',
    component: () =>
      import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () =>
      import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () =>
      import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      component: () =>
        import('@/views/dashboard/index'),
      name: '首页',
      meta: {
        title: '首页',
        icon: 'dashboard'
      }
    }]
  }

]

export const staticRoute = [
  {// 库内管理
    path: '/ware-manage',
    component: Layout,
    name: 'WareManage',
    redirect: 'noRedirect',
    meta: {
      title: 'WareManage',
      icon: 'nested'
    },
    children: [
      {// 入库
        path: 'stock-in',
        redirect: 'noRedirect',
        component: () => import('@/views/ware-manage/stock-in/index'),
        name: 'StockIn',
        meta: {
          title: 'StockIn'
        },
        children: [
          {// 入库单管理
            path: 'inbound-manage',
            component: NullRouterView,
            meta: {
              title: 'InboundManage'
            },
            children: [{
              path: '/',
              component: () => import('@/views/ware-manage/stock-in/inbound-manage/index'),
              name: 'InboundManage',
              hidden: true
            },
            {
              path: 'details',
              component: () => import('@/views/ware-manage/stock-in/inbound-manage/details'),
              name: 'InboundDetails',
              hidden: true,
              meta: {
                title: 'InboundDetails'
              }
            }]
          },
          {// 收货单管理
            path: 'receipt-manage',
            component: NullRouterView,
            meta: {
              title: 'ReceiptManage'
            },
            children: [{
              path: '/',
              component: () => import('@/views/ware-manage/stock-in/receipt-manage/index'),
              name: 'ReceiptManage',
              hidden: true
            },
            {
              path: 'details',
              component: () => import('@/views/ware-manage/stock-in/receipt-manage/details'),
              name: 'ReceiptDetails',
              hidden: true,
              meta: {
                title: 'ReceiptDetails'
              }
            }]
          },
          {// 上架单管理
            path: 'shelf-manage',
            component: NullRouterView,
            meta: {
              title: 'ShelfManage'
            },
            children: [{
              path: '/',
              component: () => import('@/views/ware-manage/stock-in/shelf-manage/index'),
              name: 'ShelfManage',
              hidden: true
            },
            {
              path: 'details',
              component: () => import('@/views/ware-manage/stock-in/shelf-manage/details'),
              name: 'ShelfDetails',
              hidden: true,
              meta: {
                title: 'ShelfDetails'
              }
            }]
          }

        ]
      }
    ]
  },
  {// 库库存管理管理
    path: '/inventory-manage',
    component: Layout,
    name: 'InventoryManage',
    redirect: 'noRedirect',
    meta: {
      title: 'InventoryManage',
      icon: 'nested'
    },
    children: [
      {// 库存查询
        path: 'inventory-inquiry',
        redirect: 'noRedirect',
        component: () => import('@/views/inventory-manage/inventory-inquiry/index'),
        name: 'InventoryInquiry',
        meta: {
          title: 'InventoryInquiry'
        },
        children: [
          {// sku维度
            path: 'sku-dimension',
            component: NullRouterView,
            meta: {
              title: 'SkuDimension'
            },
            children: [{
              path: '/',
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/index'),
              name: 'SkuDimension',
              hidden: true
            },
            {
              path: 'sku-available-inventory', // 在途库存
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
              name: 'SkuAvailableInventory',
              hidden: true,
              meta: {
                title: 'SkuAvailableInventory'
              }
            }, {
              path: 'sku-inventory-transit', // 可用库存
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
              name: 'SkuInventoryTransit',
              hidden: true,
              meta: {
                title: 'SkuInventoryTransit'
              }
            }, {
              path: 'sku-inventory-details', // 库存明细
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
              name: 'SkuInventoryDetails',
              hidden: true,
              meta: {
                title: 'SkuInventoryDetails'
              }
            },
            {
              path: 'sku-commitment-inventory', // 承诺库存
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
              name: 'SkuCommitmentInventory',
              hidden: true,
              meta: {
                title: 'SkuCommitmentInventory'
              }
            }, {
              path: 'sku-lock-inventory', // 锁定库存
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
              name: 'SkuLockInventory',
              hidden: true,
              meta: {
                title: 'SkuLockInventory'
              }
            }, {
              path: 'sku-outgoing-inventory', // 待出库库存
              component: () => import('@/views/inventory-manage/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
              name: 'SkuOutgoingInventory',
              hidden: true,
              meta: {
                title: 'SkuOutgoingInventory'
              }
            }]
          },
          {// 收货单管理
            path: 'receipt-manage',
            component: NullRouterView,
            meta: {
              title: 'ReceiptManage'
            },
            children: [{
              path: '/',
              component: () => import('@/views/ware-manage/stock-in/receipt-manage/index'),
              name: 'ReceiptManage',
              hidden: true
            },
            {
              path: 'details',
              component: () => import('@/views/ware-manage/stock-in/receipt-manage/details'),
              name: 'ReceiptDetails',
              hidden: true,
              meta: {
                title: 'ReceiptDetails'
              }
            }]
          },
          {// 上架单管理
            path: 'shelf-manage',
            component: NullRouterView,
            meta: {
              title: 'ShelfManage'
            },
            children: [{
              path: '/',
              component: () => import('@/views/ware-manage/stock-in/shelf-manage/index'),
              name: 'ShelfManage',
              hidden: true
            },
            {
              path: 'details',
              component: () => import('@/views/ware-manage/stock-in/shelf-manage/details'),
              name: 'ShelfDetails',
              hidden: true,
              meta: {
                title: 'ShelfDetails'
              }
            }]
          }

        ]
      },
      {// 库存处理
        path: 'inventory-handling',
        redirect: 'noRedirect',
        component: () => import('@/views/inventory-manage/inventory-handling/index'),
        name: 'InventoryHandling',
        meta: {
          title: 'InventoryHandling'
        },
        children: [
          {// 处置申请
            path: 'disposal-apply',
            component: NullRouterView,
            meta: {
              title: 'DisposalApply'
            },
            children: [{
              path: '/',
              component: () => import('@/views/inventory-manage/inventory-handling/disposal-apply/index'),
              name: 'DisposalApply',
              hidden: true
            },
            {
              path: 'added',
              component: () => import('@/views/inventory-manage/inventory-handling/disposal-apply/added'),
              name: 'ApplyAdded',
              hidden: true,
              meta: {
                title: 'ApplyAdded'
              }
            },
            {
              path: 'details',
              component: () => import('@/views/inventory-manage/inventory-handling/disposal-apply/details'),
              name: 'ApplyDetails',
              hidden: true,
              meta: {
                title: 'InboundDetails'
              }
            }]
          },
          {// 库存处置
            path: 'inventory-disposal',
            component: NullRouterView,
            meta: {
              title: 'InventoryDisposal'
            },
            children: [{
              path: '/',
              component: () => import('@/views/inventory-manage/inventory-handling/inventory-disposal/index'),
              name: 'InventoryDisposal',
              hidden: true
            },
            {
              path: 'added',
              component: () => import('@/views/inventory-manage/inventory-handling/inventory-disposal/added'),
              name: 'DisposalAdded',
              hidden: true,
              meta: {
                title: 'DisposalAdded'
              }
            },
            {
              path: 'details',
              component: () => import('@/views/inventory-manage/inventory-handling/inventory-disposal/details'),
              name: 'DisposalDetails',
              hidden: true,
              meta: {
                title: 'DisposalDetails'
              }
            }]
          }
        ]
      }
    ]
  },
  {
    path: '/period-manage',
    component: Layout,
    name: 'PeriodManage',
    redirect: 'noRedirect',
    meta: {
      title: 'PeriodManage',
      icon: 'nested'
    },
    children: [
      {
        path: 'inventory-results',
        component: NullRouterView,
        meta: {
          title: 'InventoryResults'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/period-manage/inventory-results/index'),
          name: 'InventoryResults',
          hidden: true
        },
        {
          path: 'details',
          component: () => import('@/views/period-manage/inventory-results/details'),
          name: 'ResultsDetail',
          hidden: true,
          meta: {
            title: 'ResultsDetails'
          }
        }]
      },

      {
        path: 'inventory-balance',
        component: NullRouterView,
        meta: {
          title: 'InventoryBalance'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/period-manage/inventory-balance/index'),
          name: 'InventoryBalance',
          hidden: true
        },
        {
          path: 'details',
          component: () => import('@/views/period-manage/inventory-balance/details'),
          name: 'BalanceDetail',
          hidden: true,
          meta: {
            title: 'BalanceDetails'
          }
        }]
      }
    ]
  },
  {
    path: '/inventory-inquiry',
    component: Layout,
    name: 'InventoryInquiry',
    redirect: 'noRedirect',
    meta: {
      title: 'InventoryInquiry',
      icon: 'nested'
    },
    children: [
      {
        path: 'sku-dimension',
        component: NullRouterView,
        name: 'sku-dimension',
        // redirect: '/inventory-inquiry/sku-dimension/sku',
        meta: {
          title: 'SKU维度',
          icon: 'nested'
        },
        // path: 'sku-dimension',
        // redirect: 'noRedirect',
        // component: () => import('@/views/inventory-inquiry/sku-dimension/index'),
        // name: 'SkuDimension',
        // meta: {
        //   title: 'SkuDimension'
        // },
        children: [
          {
            path: '/',
            hidden: true,
            component: () => import('@/views/inventory-inquiry/sku-dimension/index'),
            // name: 'SkuDimension',
            meta: {
              title: 'SKU维度',
              icon: 'nested'
            }
          },
          {
            path: 'sku-available-inventory', // 在途库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
            name: 'SkuAvailableInventory',
            hidden: true,
            meta: {
              title: 'SkuAvailableInventory'
            }
          },
          {
            path: 'sku-inventory-transit', // 可用库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
            name: 'SkuInventoryTransit',
            hidden: true,
            meta: {
              title: 'SkuInventoryTransit'
            }
          },
          {
            path: 'sku-inventory-details', // 库存明细
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
            name: 'SkuInventoryDetails',
            hidden: true,
            meta: {
              title: 'SkuInventoryDetails'
            }
          },
          {
            path: 'sku-commitment-inventory', // 承诺库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
            name: 'SkuCommitmentInventory',
            hidden: true,
            meta: {
              title: 'SkuCommitmentInventory'
            }
          }, {
            path: 'sku-lock-inventory', // 锁定库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
            name: 'SkuLockInventory',
            hidden: true,
            meta: {
              title: 'SkuLockInventory'
            }
          },
          {
            path: 'sku-outgoing-inventory', // 待出库库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
            name: 'SkuOutgoingInventory',
            hidden: true,
            meta: {
              title: 'SkuOutgoingInventory'
            }
          }

          // {
          //   path: 'sku-available-inventory', // 在途库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuAvailableInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //     name: 'SkuAvailableInventory',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-available-inventory',
          //   component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //   name: 'SkuAvailableInventory',
          //   hidden: true
          // },
          // {
          //   path: 'sku-inventory-details',
          //   component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
          //   name: 'SkuInventoryDetails',
          //   hidden: true
          // },
          // {
          //   path: 'sku-inventory-transit',
          //   CheckReview: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
          //   name: 'SkuInventoryTransit',
          //   hidden: true
          // },
          // {
          //   path: 'sku-available-inventory', // 在途库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuAvailableInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //     name: 'SkuAvailableInventory',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-inventory-transit', // 可用库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuInventoryTransit'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
          //     name: 'SkuInventoryTransit',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-inventory-details', // 库存明细
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuInventoryDetails'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
          //     name: 'SkuInventoryDetails',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-commitment-inventory', // 承诺库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuCommitmentInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
          //     name: 'SkuCommitmentInventory',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-lock-inventory', // 锁定库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuLockInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
          //     name: 'SkuLockInventory',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-outgoing-inventory', // 待出库库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuOutgoingInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
          //     name: 'SkuOutgoingInventory',
          //     hidden: false
          //   }]
          // }

        ]
      },

      {
        path: 'warehouse-dimension',
        component: () => import('@/views/inventory-inquiry/warehouse'),
        name: 'warehouse-dimension',
        meta: {
          title: '仓库维度',
          icon: 'nested'
        },
        children: [
          {
            path: 'ware-inventory-detail',
            component: () => import('@/views/inventory-inquiry/warehouse/ware-inventory-detail'),
            name: 'Company',
            hidden: true,
            meta: {
              title: '仓库库存明细',
              icon: 'nested'
            }
          },
          {
            path: 'ware-transit-inventory',
            component: () => import('@/views/inventory-inquiry/warehouse/ware-transit-inventory'),
            name: 'Company',
            hidden: true,
            meta: {
              title: '仓库在途库存',
              icon: 'nested'
            }
          }

        ]
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  },
  // 库存查询
  {
    path: '/inventory-inquiry',
    component: Layout,
    name: 'InventoryInquiry',
    redirect: 'noRedirect',
    meta: {
      title: 'InventoryInquiry',
      icon: 'nested'
    },
    children: [
      {
        path: 'sku-dimension',
        component: NullRouterView,
        // component: () => import('@/views/stock-in/harvest'),
        meta: {
          title: 'SkuDimension'
        },
        // path: 'sku-dimension',
        // redirect: 'noRedirect',
        // component: () => import('@/views/inventory-inquiry/sku-dimension/index'),
        // name: 'SkuDimension',
        // meta: {
        //   title: 'SkuDimension'
        // },
        children: [
          {
            path: '/',
            component: () => import('@/views/inventory-inquiry/sku-dimension/index'),
            name: 'SkuDimension',
            hidden: true
          },
          {
            path: 'sku-available-inventory', // 可用库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
            name: 'SkuAvailableInventory',
            hidden: true,
            meta: {
              title: 'SkuAvailableInventory'
            }
          },
          {
            path: 'sku-inventory-transit', // 在途库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
            name: 'SkuInventoryTransit',
            hidden: true,
            meta: {
              title: 'SkuInventoryTransit'
            }
          },
          {
            path: 'sku-inventory-details', // 库存明细
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
            name: 'SkuInventoryDetails',
            hidden: true,
            meta: {
              title: 'SkuInventoryDetails'
            }
          },
          {
            path: 'sku-commitment-inventory', // 承诺库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
            name: 'SkuCommitmentInventory',
            hidden: true,
            meta: {
              title: 'SkuCommitmentInventory'
            }
          }, {
            path: 'sku-lock-inventory', // 锁定库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
            name: 'SkuLockInventory',
            hidden: true,
            meta: {
              title: 'SkuLockInventory'
            }
          },
          {
            path: 'sku-outgoing-inventory', // 待出库库存
            component: () => import('@/views/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
            name: 'SkuOutgoingInventory',
            hidden: true,
            meta: {
              title: 'SkuOutgoingInventory'
            }
          }

          // {
          //   path: 'sku-available-inventory', // 在途库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuAvailableInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //     name: 'SkuAvailableInventory',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-available-inventory',
          //   component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //   name: 'SkuAvailableInventory',
          //   hidden: true
          // },
          // {
          //   path: 'sku-inventory-details',
          //   component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
          //   name: 'SkuInventoryDetails',
          //   hidden: true
          // },
          // {
          //   path: 'sku-inventory-transit',
          //   CheckReview: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
          //   name: 'SkuInventoryTransit',
          //   hidden: true
          // },
          // {
          //   path: 'sku-available-inventory', // 在途库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuAvailableInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-available-inventory/index'),
          //     name: 'SkuAvailableInventory',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-inventory-transit', // 可用库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuInventoryTransit'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-transit/index'),
          //     name: 'SkuInventoryTransit',
          //     hidden: false
          //   }
          //   ]
          // },
          // {
          //   path: 'sku-inventory-details', // 库存明细
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuInventoryDetails'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-inventory-details/index'),
          //     name: 'SkuInventoryDetails',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-commitment-inventory', // 承诺库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuCommitmentInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-commitment-inventory/index'),
          //     name: 'SkuCommitmentInventory',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-lock-inventory', // 锁定库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuLockInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-lock-inventory/index'),
          //     name: 'SkuLockInventory',
          //     hidden: false
          //   }]
          // },
          // {
          //   path: 'sku-outgoing-inventory', // 待出库库存
          //   component: NullRouterView,
          //   meta: {
          //     title: 'SkuOutgoingInventory'
          //   },
          //   children: [{
          //     path: '/', // 出库单 index
          //     component: () => import('@/views/inventory-inquiry/sku-dimension/sku-outgoing-inventory/index'),
          //     name: 'SkuOutgoingInventory',
          //     hidden: false
          //   }]
          // }

        ]
      }

    ]
  }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
