import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import permission from '@/directive/permission'
import { mockXHR } from '../mock'
import Print from '@/plugs/print'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'

Vue.filter('dataFormat', function(originVal) {
  if (originVal) {
    const dt = new Date(originVal)
    var y = dt.getFullYear()
    var m = (dt.getMonth() + 1 + '').padStart(2, '0')
    var d = (dt.getDate() + '').padStart(2, '0')
    var h = (dt.getHours() + '').padStart(2, '0')
    var mm = (dt.getMinutes() + '').padStart(2, '0')
    var s = (dt.getSeconds() + '').padStart(2, '0')
    return y + '-' + m + '-' + d + '  ' + h + ':' + mm + ':' + s
  } else {
    return ''
  }
})

Vue.use(VXETable)

Vue.use(permission)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(Print)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

if (process.env.NODE_ENV === 'development') {
  mockXHR()
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
