import i18n from '@/lang'

// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = this.$te('route.' + title)
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    const translatedTitle = this.$t('route.' + title)

    return translatedTitle
  }
  return title
}

// 重新封装多语言方法
export function $selt(args) {
  const hasKey = i18n.te('route.' + args)
  if (hasKey) {
    const translatedTitle = i18n.tc('route.' + args)
    return translatedTitle
  }
  return args
}
