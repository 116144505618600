/** *****
 *
 *
 * 头部缓存块
 *
 * ****
 * ***/

const state = {
  tabsOption: [{
    route: '/home',
    title: '首页',
    name: 'home'
  }],
  currentIndex: '/home',
  breadcrumbList: []
}

const mutations = {
  ADD_TAB: (state, data) => {
    state.tabsOption.push(data)
  },
  DELETE_TAB: (state, route) => {
    const index = state.tabsOption.findIndex(v => v.route === route)
    state.tabsOption.splice(index, 1)
  },
  SET_TAB: (state, index) => {
    state.currentIndex = index
  },
  CLEAR_TAB: (state) => {
    // 初始化tab
    state.tabsOption = [{ route: '/home', title: '首页', name: 'home' }]
  }
}

const actions = {

}
// const getters = {
//   getCurrentIndex(state) {
//     return state.currentIndex
//   },
//   getTabsOption(state) {
//     return state.tabsOption
//   }
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions
  // getters
}
