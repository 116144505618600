import Mock from 'mockjs'

const data = Mock.mock({
  'items|30': [{
    id: '@id',
    description: '@sentence(10, 20)',
    'status|1': [true, false],
    name: '@cname',
    classify: '@cname',
    image: "@image('100x100', '#000', '#FF0', 'png', 'xixi')",
    display_time: '@datetime',
    pageviews: '@integer(300, 5000)'
  }]
})

export default [
  {
    url: '/demo',
    type: 'get',
    response: () => {
      const items = data.items
      return {
        code: 0,
        msg: '操作成功',
        datas: {
          total: items.length,
          items: items
        }
      }
    }
  }
]
