export default {
  route: {
    dashboard: '首页',
    InventoryInquiry: '账面即时库存查询',
    SkuDimension: 'SKU维度',
    SkuInventoryDetails: 'SKU库存明细',
    SkuInventoryPlan: 'SKU计划库存',
    SkuAvailableInventory: 'SKU可用库存',
    SkuPurchaseInventoryTransit: 'SKU采购在途库存',
    SkuPurchaseInventoryDelivery: 'SKU发货在途库存',
    SkuCommitmentInventory: 'SKU承诺库存',
    SkuLockInventory: 'SKU锁定库存',
    SkuOutgoingInventory: 'SKU待出库库存',
    Warehouse: '仓库维度',
    WareInventoryDetail: '仓库库存明细',
    WareAvailableInventory: '仓库可用库存',
    WareTransitInventory: '仓库在途库存',
    WareCommitmentInventory: '仓库承诺库存',
    WareLockInventory: '仓库锁定库存',
    WareOutgoingInventory: '仓库待出库库存',
    Consignor: '货主维度',
    ConsInventoryDetail: '货主库存明细',
    ConsAvailableInventory: '货主可用库存',
    ConsTransitInventory: '货主出库在途库存',
    consDeliveryTransit: '货主采购在途库存',
    consPlannedInventory: '货主计划库存',
    ConsLockInventory: '货主锁定库存',
    ConsOutgoingInventory: '货主待出库库存',
    WareManage: '库内管理',
    StockIn: '入库管理',
    InboundManage: '入库单管理',
    InboundDetails: '入库单详情',
    ReceiptManage: '收货单管理',
    ReceiptDetails: '收货单详情',
    ShelfManage: '上架单管理',
    ShelfDetails: '上架单详情',
    StockOut: '出库管理',
    exManage: '出库单管理',
    exManageDetails: '出库单管理详情',
    shipmentManage: 'Shipment管理',
    shipmentManageDetails: 'Shipment详情',
    waybillManage: '发运单管理',
    waybillManageDetails: '发运单管理详情',
    AmazonShipment: '生成shipment',
    CeateShipment: '生成shipment',
    GeneratShipment: '创建shipment计划',
    InventoryManage: '库存管理',
    InventoryHandling: '库存处理',
    DisposalApply: '处置申请',
    InventoryDisposal: '库存处置',
    ApplyAdded: '处置申请',
    ApplyDetails: '处置申请详情',
    DisposalAdded: '库存处置',
    DisposalDetails: '库存处置详情',
    PeriodManage: '期末管理',
    ExportFilePack: '文件导出',
    InventoryResults: '库存计算结果',
    ResultsDetail: '结存计算详情',
    InventoryBalance: '库存结存',
    BalanceDetails: '库存结存详情',
    DiffHandling: '差异处理',
    HandlingDetail: '差异明细',
    BasicData: '基础信息',
    Storage: '仓库管理',
    Add: '新增/修改',
    ManualDefict: '库存对冲盘盈盘亏',
    TransitManage: '在途库存管理',
    ExportFileIms: 'ExportFile',
    imsWidget: 'ims小工具',
    BrushData: '手动创建收货单任务'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: 'IMS系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    verificationCode: '验证码'
  },
  footer: {
    help: '帮助',
    privacy: '隐私',
    clause: '条款'
  },
  page: {
    // summer
    required: '必填',
    alreadySel: '已选择',
    rowS: '项',
    empty: '清空',
    fileText: '将文件拖到此处，或',
    fileTextUpload: '点击上传',
    OnlyXlsAndXlsxFilesCanBeUploaded: '只能上传.xls与.xlsx文件',
    UploadFileSizeShouldNotExceed: '上传文件大小不能超过 1MB!',
    UploadAndImportFiles: '上传导入文件',
    TheImportDetails: '导入详情',
    setAccountDisplay: '设置账号显示',
    inventoryUpload: '库存上传',
    ebayUploadTip: '您确定要上传库存至EBAY平台吗？',
    selectOneData: '至少选择一条有效数据',
    /** 公用 */
    LoginFailed: '登陆失效',
    Relogin: '重新登陆',
    HaveBeenLoggedOut: '你已经被强制登出,你可以关闭弹窗停留在该页面或者重新登录',
    PasswordManagement: '密码管理',
    Password: '密码',
    Newpassword: '新密码',
    ConfirmPassword: '确认密码',
    ResetPassword: '重置密码',
    Character: '角色',
    Staff: '员工',
    InformationManagement: '信息管理',
    phoneNumber: '手机号',
    username: '用户名',
    Email: '邮箱',
    Avatar: '头像',
    requestTimeOut: '请求已超时,请重试.',
    homepage: '首页',
    Details: '详情',
    search: '搜索',
    reset: '重置',
    expand: '展开',
    hide: '隐藏',
    create: '新建',
    export: '导出',
    stockHistoryExport: '库存历史导出',
    startTimeEndTime: '开始时间—结束时间',
    No: '序号',
    gender: '性别',
    man: '男',
    woman: '女',
    inputPlaceholder: '请输入',
    selectPlaceholder: '请选择',
    enabled: '启用',
    disable: '禁用',
    operate: '操作',
    view: '查看',
    modify: '修改',
    invalid: '作废',
    createDate: '创建日期',
    createTime: '创建时间',
    receiveTime: '领取时间',
    finishTime: '完成时间',
    handleTime: '操作时间',
    createByName: '创建人',
    warehouse: '仓库',
    Linkman: '联系人',
    sure: '确定',
    delete: '删除',
    add: '新增',
    import: '导入',
    close: '关闭',
    Specifications: '规格型号',
    total: '合计',
    status: '状态',
    Prompt: '提示',
    // shipment明细
    addOutboundDetails: '添加出库明细',
    addLables: '添加标签',
    notApprove: '未核准',
    approved: '已核准',
    approveShipment: '核准shipment',
    downloadPortMark: '下载港口标',
    pickTotalNum: '拣货总数量',
    totalBoxNumLabelled: '贴标总箱数',
    shipmentLabelDetail: 'shipment标签明细',
    combinationLabelReplenishPrint: '组合标签打印',
    exportLabels: '标签导出',
    LabelCode: '标签号',
    ShippingStatus: '发运状态',
    trajectory: '轨迹明细',
    ShipmentDetails: 'shipment明细',
    pickDiff: '拣货差异',
    labelDiff: '标签差异',
    shippingDiff: '发运差异',
    ReplenishPickingTask: '补拣任务',
    shipmentBoxNum: 'shipment箱数',
    shipmentNum: 'shipment数量',
    pickedNum: '已拣数量',
    shippingNum: '发运数量',
    shipmentVolume: 'shipment体积',
    amazonNumber: '亚马逊数量',
    modifyShipmentDetail: '修改shipment明细数量',
    reduceQuantity: '减少数量',
    reducedQuantityGreater0: '减少的数量需大于0, 小于或等于',
    modifyAfterTips: '(修改后请人工更新亚马逊后台数量)',
    // shipment管理
    DestinationWarehouse: '目的仓库',
    expectContainerNo: '预期货柜/装柜号',
    approvedStatus: '核准状态',
    transactionRecord: '单据状态',
    distributionContainer: '分配货柜',
    forwarding: '转发运',
    ullageShipping: '缺量发运',
    expectedContainerNum: '预期货柜号',
    shippingWarehouse: '发货仓库',
    sortingArea: '分拣储区',
    totalBoxNum: '总箱数',
    totalNum: '总数量',
    pickingQuantity: '拣货数量',
    numberOfLabels: '标签数量',
    volume: '体积',
    shoeboxVolume: '鞋盒体积',
    CartonSizeVolume: '箱规体积',
    shoeboxTotalVolume: '鞋盒总体积',
    UnshippedShoeboxVolume: '鞋盒未发体积',
    TobeshippedShoeboxVolume: '鞋盒待发体积',
    ShippedShoeboxVolume: '鞋盒已发体积',
    CartonSizeTotalVolume: '箱规总体积',
    UnshippedCartonVolume: '箱规未发体积',
    TobeshippedCartonVolume: '箱规待发体积',
    ShippedCartonVolume: '箱规已发体积',
    stopShipmment: '终止shipmment',
    abnormalStuffing: '装柜异常处理',
    expectedVolume: '预期箱规体积',
    RealCartonVolume: '实际箱规体积',
    walmartPairs: '沃尔玛数量',
    walmartShipmentPairsUpdate: '沃尔玛商品数量更新',
    shipmentPairs: 'shipment数量',
    // shopshipment
    outboundSite: '出库站点',
    canCreateNumber: '可生成数量',
    createShopShipment: '生成门店shipment',
    isUse: '是否使用',
    ShippingPort: '发运港口',
    // 销售出库
    addOutboundOrder: '新建出库单',
    outboundOrderNo: '出库单号',
    sourceOrderNo: '来源单号',
    outboundType: '出库类型',
    sendToSite: '发至站点',
    outboundWH: '出库仓库',
    outboundNum: '出库数量',
    outboundBoxNum: '出库箱数',
    terminateOutbound: '终止出库',
    createShipment: '生成shipment',
    EmbeddingAmazon: '生成shipment',
    createShipmentPlan: '创建shipment计划',
    importBrushData: '导入刷仓数据',
    outboundOrderBaseInfo: '出库单基本信息',
    outboundOrderDetail: '出库明细',
    outboundStatus: '出库状态',
    outboundPlatform: '出库月台',
    sendToPlatform: '发至平台',
    shouldOutboundNum: '应出库数量',
    shouldOutboundBoxNum: '应出库箱数',
    estimatedDeliveryTime: '预计发货时间',
    OnekeySales: '一键销售',
    maxSalesVolume: '最大销售量',
    salesVolume: '销售数量',
    mergeOutboundOrder: '合并出库单',
    sameTypeSiteStatus: '请选择相同类型,站点,且状态为已生效的出库单',
    createNumber: '生成数量',
    createVolume: '生成体积',
    ReferenceVolume: '参考体积',
    boardsVolume: '个板对应体积',
    GenerateInventoryVolume: '生成出库单体积',
    NumberOfPalletsGenerated: '生成出库单板数',
    SureToMerge: '确认合并',
    generateANewOutbound: '您确定要生成新的出库单吗?',
    ShoeBoxVolume: '鞋盒体积',
    CartonVolume: '箱子体积'

  },
  title: {
    starttime: '开始时间',
    endtime: '结束时间',
    Permission: '权限',
    back: '返回',
    Save: '保存',
    SaveAndSend: '保存并发布',
    Edit: '编辑',
    Replace: '替换',
    BasicInformation: '基本信息',
    submit: '提交',
    cancel: '取 消',
    confirm: '确 认',
    ExportDetail: '导出明细',
    // 平台SKU信息
    Color: '颜色',
    Size: '尺码',
    Category: '类别',
    Yes: '是',
    No: '否',
    select: '请至少选中一条',
    OrderAll: '全部',
    to: '至',
    platform: '平台',
    order: '订单',
    copy: '复制',
    None: '无',
    create: '创建',
    InventoryDetails: '库存明细',
    /** 公用 */
    addPhotos: '添加图片',
    requestTimeOut: '请求已超时,请重试.',
    homepage: '首页',
    Details: '详情',
    search: '搜索',
    reset: '重置',
    expand: '展开',
    hide: '隐藏',
    export: '导出',
    stockHistoryExport: '库存历史导出',
    startTimeEndTime: '开始时间—结束时间',
    gender: '性别',
    man: '男',
    woman: '女',
    inputPlaceholder: '请输入',
    inputEnter: 'enter键分隔',
    imputEnter: '请按 Enter键查询详情',
    inputCompletePlaceholder: '请补充完整规则',
    selectPlaceholder: '请选择',
    enabled: '启用',
    disable: '禁用',
    operate: '操作',
    view: '查看',
    modify: '修改',
    invalid: '作废',
    createDate: '创建日期',
    createTime: '创建时间',
    receiveTime: '领取时间',
    finishTime: '完成时间',
    handleTime: '操作时间',
    createByName: '创建人',
    lastModifyTime: '最后修改时间',
    warehouse: '仓库',
    serviceprovider: '服务商',
    Linkman: '联系人',
    sure: '确定',
    delete: '删除',
    add: '新增',
    import: '导入',
    templateDownload: '模板下载',
    tray: '托盘',
    storageArea: '储区',
    expectedNum: '预期数量',
    expectedBoxNum: '预期箱数',
    close: '关闭',
    Specifications: '规格型号',
    total: '合计',
    refreshtotal: '刷新合计',
    alreadySel: '已选择',
    rowS: '项',
    batchRemove: '批量删除',
    batchCreate: '批量新增',
    isSplit: '是否(B0/X0/其他)拆分',
    typeBy: '所属分类',
    stopReport: '终止提报',
    reportReason: '提报原因',
    inputStopReason: '请输入终止原因',
    AddTXT: '添加',
    plzChooseDate: '请选择日期',
    plzChooseDateTime: '请选择日期时间',
    pdaQrcode: 'pda下载更新',
    // Shipment管理
    printtypes: '请选择打印类型',
    printType: '打印类型',
    LabelPaper: '标签纸',
    page1: '1页1个',
    page2: '1页2个',
    page4: '1页4个',
    page6: '1页6个',
    // 文件导出
    ExportTaskName: '导出任务名称',
    ExportCompleTime: '导出完成时间',
    AbnormalInformation: '异常信息'

  }
}
