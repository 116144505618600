<template>
  <div>
    <!-- <el-tabs
      id="Tabs"
      v-model="currentKey"
      type="card"
      class="head_card_tabs"
      closable
      @tab-click="clickTab"
      @tab-remove="removeTab"
    >
      <el-tab-pane
        v-for="item in tabsOption"
        :key="item.route"
        :closable="item.route !== '/home'"
        :label="item.title"
        :name="item.route"
      />
    </el-tabs> -->

    <section class="app-main">
      <transition name="fade-transform" mode="out-in">
        <el-card>
          <router-view :key="key" />
        </el-card>
      </transition>
      <Footer />
    </section>
  </div>
</template>

<script>
import Footer from './Footer'
import store from '@/store'
// import TopTabsNav from './TopTabsNav'

export default {
  name: 'AppMain',
  components: {
    Footer

  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    },
    tabsOption() {
      return this.$store.state.tabsHeadCard.tabsOption
    },
    currentKey: {
      get() {
        return this.$store.state.tabsHeadCard.currentIndex
      },
      set() {

      }
    }
  },
  methods: {
    clickTab(tabName, event) {
      console.log('111', this.Component, this.$route)
      store.commit('tabsHeadCard/SET_TAB', tabName.paneName)
      this.$router.replace({ path: tabName.paneName })
    },
    removeTab(tabName) {
      if (tabName === '/home') {
        return
      }
      store.commit('tabsHeadCard/DELETE_TAB', tabName)
      if (this.currentKey === tabName) {
        const { tabsOption } = this
        if (tabsOption && tabsOption.length) {
          store.commit(
            'tabsHeadCard/SET_TAB',
            tabsOption[tabsOption.length - 1].route
          )
          this.$router.replace({ path: this.currentKey })
        } else {
          this.$router.replace({ path: '/' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
  background-color: #f0f2f5;
}

.app-container {
  background-color: #fff;
  border-radius: 5px;
}
body > .el-container {
  margin-bottom: 40px;
}
.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head_card_tabs > .el-tabs__header {
      margin: 0 0 2px;

}
</style>
