const state = {
  shipmentTableDatasSelect: ''
}

const mutations = {
  INIT_TABLE_SELECT: (state, data) => {
    state.shipmentTableDatasSelect = data
  }
}

const actions = {
  initTableSelect({ commit }, data) {
    commit('INIT_TABLE_SELECT', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
